<template>
  <div>
    <v-row class="fill-height" no-gutters>
      <v-flex
        xs4
        dark
        mini-variant
        mini-variant-width="56"
        permanent
        class="opcionesMenu"
        height="100vh"
      >
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img :src="foto" :alt="alt" class="fotoPerfil"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense align-content-center>
          <v-list-item v-for="item in items" :key="item.title" px-2>
            <v-list-item-action>
              <router-link
                @click.native="redireccionar(item.url)"
                :to="'#'"
                class="links"
              >
                <v-icon color="#ffffff">{{ item.icon }} </v-icon>

                <p class="opcionesTitulo">{{ item.title }}</p>
              </router-link>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-flex>
      <v-flex xs8>
        <v-list class="grow">
          <v-list-item
            v-for="link in opcionMenu"
            :key="link.id"
            :class="link.class || 'inactive'"
            width="100vh"
          >
            <router-link
              v-if="link.url"
              :to="link.url"
              :class="link.class || 'inactive'"
            >
              {{ link.nombre }}</router-link
            >
            <v-list-item-title
              v-else
              v-text="link.nombre"
              :class="link.class || 'inactive'"
            ></v-list-item-title>
          </v-list-item>

          <hr />
          <!-- <label class="mx-2 px-2 py-0" for="">
            {{ currencyFormat(datosPrincipales.amount) }}
          </label> -->
          <v-card class="mx-2 my-1">
            <v-card-text>
              <h3>DATOS DE COTIZACIÓN</h3>
              <v-input class="mt-2" hide-details dense>
                <p>
                  Valor de la Mercancia:
                  {{ currencyFormat(datosPrincipales.amount) }}
                </p>
              </v-input>
              <v-autocomplete
                class="py-0"
                :items="modality"
                label="Sentido"
                v-model="datosPrincipales.idmodality"
                readonly
                dense
              ></v-autocomplete>

              <v-autocomplete
                class="py-0"
                :items="shipment"
                label="Carga"
                dense
                readonly
                v-model="datosPrincipales.idshipment"
              ></v-autocomplete>

              <v-autocomplete
                class=""
                :items="incoterm"
                label="Incoterm"
                dense
                readonly
                v-model="datosPrincipales.idincoterm"
              ></v-autocomplete>
              <div v-if="datosPrincipales.idshipment">
                <div
                  class=""
                  v-if="
                    datosPrincipales.idshipment.value == 2 ||
                    datosPrincipales.idshipment == 2
                  "
                >
                  Datos Contendor:
                  <p
                    class="my-0 px-0"
                    v-for="d in datosPrincipales.containers"
                    :key="d"
                  >
                    {{ d.description }} - {{ d.cantidad }}
                  </p>
                </div>
                <div
                  class=""
                  v-if="
                    datosPrincipales.idshipment.value != 2 &&
                    datosPrincipales.idshipment != 2
                  "
                >
                  <p class="my-0 px-0">
                    Cantidad: {{ datosPrincipales.bultos }} uni
                  </p>
                  <p class="my-0 px-0">
                    Volumen:{{ datosPrincipales.metroscc }} m <sup>3</sup>
                  </p>
                  <p class="my-0 px-0">Peso: {{ datosPrincipales.kg }} kg.</p>
                </div>
              </div>
              <!--  -->
            </v-card-text>
          </v-card>
        </v-list>
      </v-flex>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import funcion from "@/mixins/funciones";
import Swal from "sweetalert2";
export default {
  props: ["tipo"],
  name: "opciones",
  mixins: [funcion],
  data() {
    return {
      mostrar: true,
      foto: JSON.parse(localStorage.getItem("datos")).path,
      alt: JSON.parse(localStorage.getItem("datos")).names,
      items: [
        { title: "Pre cotizacion", icon: "mdi-forum", url: "/pre_cotizacion" },
        { title: "Cotización", icon: "mdi-cash-multiple", url: "/listado" },
        { title: "Reporte", icon: "mdi-forum", url: "/reporte" },
        { title: "Ficheros", icon: "mdi-forum", url: "/ficheros" },
        { title: "Casilleros", icon: "mdi-forum", url: "/listado_casillero" },
        // { title: "About", icon: "mdi-forum" },
      ],
      links: null,
      mini: false,
      activo1: null,
      opciones: [],
    };
  },
  mounted() {
    this.activo1 = this.$store.getters.step1flag;
    this.activo2 = this.$store.getters.step2flag;
    this.activo3 = this.$store.getters.step3flag;
  },
  computed: {
    ...mapState([
      "step2flag",
      "step3flag",
      "datosPrincipalesClass",
      "datosCargaClass",
      "serviciosClass",
      "cotizarClass",
      "opcionMenu",
      "datosPrincipales",
      "modality",
      "shipment",
      "incoterm",
    ]),
  },
  methods: {
    ...mapMutations(["opcioneMenuLista"]),
    redireccionar(ruta) {
      if (
        this.tipo == "nuevo" ||
        this.tipo == "editar" ||
        this.tipo == "precotizacion"
      ) {
        Swal.fire({
          html: `¿ESTAS SEGURO QUE DESEAS SALIR? `,
          titleText: `NO SE GUARDARA EL REGISTRO`,
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: "Salir",
          denyButtonText: `Continuar`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.$router.push(ruta).catch(() => {});
          } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
          }
        });
      } else {
        this.$router.push(ruta).catch(() => {});
      }
    },
  },
};
</script>

<style>
.opcionesMenu {
  background: #2d3b44 !important;
  color: #ffff !important;
  height: 100vh;
  padding-left: auto !important;
}
.grow {
  background: #3f4d67 !important;
  min-height: 100%;
}
.active {
  background: #ffffff !important;
  height: auto;
  border-radius: 0 15px 15px 0;
  margin-right: 0.5rem;
  font-weight: bold;
}
.inactive {
  background: #3f4d67 !important;
  color: white !important;
  font-weight: bold;
}
v-list-item {
  background: #3f4d67 !important;
  color: white !important;
}

.v-navigation-drawer__content {
  overflow-y: hidden !important;
  overflow-x: auto !important;
  box-sizing: border-box;
}
.opcionesTitulo {
  font-size: 0.7rem;
  margin: auto !important;
  color: #ffffff;
}
.icono {
  color: #ffffff;
}
.fotoPerfil {
  margin: auto !important;
}

.links {
  text-decoration: none !important;
  color: #000000 !important;
  font-weight: bold;
}

.datos {
  color: #ffffff !important;
}
</style>
